<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="30%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <ax-form ref="form" :formBuilder="formBuilder"></ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="submit"
          :loading="saveType"
          style="background-color: #556bff"
          >确 认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
    
    <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "../api";
const formList = [
  {
    label: "店铺名称",
    type: "input",
    model: "name",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "所属网格",
    type: "",
    model: "pcode",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "店铺地址",
    type: "textarea",
    model: "address",
    options: { minRows: 4, maxRows: 4, placeholder: "请输入" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "店主",
    type: "",
    model: "graphList",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "联系电话",
    type: "",
    model: "graphList",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "是否启用",
    type: "switch",
    model: "switch",
    options: {},
    formItem: {},
    col: { span: 1 },
    rules: [],
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      title: "",
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 130 },
        { col: { span: 8 } }
      ),
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record) {
      this.visible = true;
      this.title = record.titles;
    },

    closeModal() {
      this.visible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
    <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>