<!--店铺管理 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      :dynamicSlotNames="['gridId']"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <a-switch v-model="record.status" slot="status" slot-scope="{ record }" />
    </ax-table>
    <!-- 添加弹窗 -->
     <addStore ref="addStore"></addStore>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "店铺名称",
    type: "input",
    model: "typeName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "店铺地址",
    type: "input",
    model: "typeName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "店主",
    type: "input",
    model: "typeName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "select",
    model: "typeNames",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
import addStore from "./components/addStore.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addStore },
  data() {
    //这里存放数据
    return {
      api,
      // 表格模块数据
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "店铺名称",
            dataIndex: "parentTypeName",
            ellipsis: true,
            width: 120,
          },
          {
            title: "所属网格",
            dataIndex: "typeName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "店铺地址",
            dataIndex: "referScore",
            ellipsis: false,
            width: 150,
          },
          {
            title: "店主",
            dataIndex: "createBy1",
            ellipsis: false,
            width: 150,
          },
          {
            title: "联系电话",
            dataIndex: "createTime2",
            ellipsis: false,
            width: 150,
          },
          {
            title: "操作人",
            dataIndex: "createBy",
            ellipsis: false,
            width: 150,
          },
          {
            title: "操作时间",
            dataIndex: "createTime",
            ellipsis: false,
            width: 150,
          },
          {
            title: "是否启用",
            dataIndex: "status",
            ellipsis: false,
            width: 150,
          },
        ],
        false,
        {
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#2A5CFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "delete",
                  type: "#FA5959",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [{ name: "add", text: "新增" }],
      dataSourceParams: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
        this.$refs.addStore.openModal({...record,titles:'编辑店铺'})
          break;
      }
    },

    add() {
      this.$refs.addStore.openModal({titles:'新增店铺'})
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.ant-switch-checked {
  background-color: #2a5efe !important;
}
.ant-switch {
  background-color: #e6eaed;
}
</style>